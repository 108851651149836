.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.circle {
  width: 1em; /* Set width relative to font size */
  height: 1em; /* Set height relative to font size */
  background: linear-gradient(90deg, blue, green);
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 0.5em auto;
  padding: 0.5em; /* Add padding around the text */
}

#app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh; /* Make the parent div take the full height of the viewport */
}